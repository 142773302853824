<script>
import EventBus from "@/utils/event-bus"

export default {
  props: {
    formTitle: {
      type: String,
      default: null
    },

    formId: {
      type: Number,
      default: 0
    },

    formValue: {
      type: Number,
      default: 0
    },

    sections: {
      type: Array,
      default () {
        return []
      }
    }
  },

  data () {
    return {
      activeSection: this.sections[0],
      activeSectionStep: 0
    }
  },

  methods: {
    handleSlideChange (slide, formData, index) {
      this.$nextTick(() => {
        if (index === 0) {
          window.dataLayer.push({
            event: "addToCart",
            ecommerce: {
              currencyCode: "NOK",
              add: {
                products: [{
                  id: "resept-" + this.formId,
                  name: "Resept " + this.formId,
                  price: this.conversionValue,
                  brand: this.$app.name,
                  quantity: 1
                }]
              }
            }
          })

          // GA4 implementation.
          window.dataLayer.push({ ecommerce: null })  // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "view_item",
            ecommerce: {
              currency: "NOK",
              value: this.conversionValue,
              items: [
                {
                  item_id: "resept-" + this.formId,
                  item_name: "Resept " + this.formId,
                  item_brand: this.$app.name,
                  price: this.conversionValue,
                  quantity: 1
                }
              ]
            }
          })
        }

        window.dataLayer.push({
          event: "contentView",
          contentView: `/${this.$route.params.slug}/section-${this.sectionStep}/${this.slugify(slide.headline)}`,
          contentViewName: slide.headline,
          slideIdentifier: slide.tags.gtm.gtm_identifier
        })

        this.activeSectionStep = this.sectionStep
      })

      this.activeSection = slide.section
    },

    handleCheckoutComplete (data) {
      if (data.paymentMethod === "vipps") {
        window.dataLayer.push({
          event: "checkout",
          ecommerce: {
            actionField: {
              step: 1,
              option: "Vipps"
            },
            checkout: {
              products: [{
                id: "resept-" + this.formId,
                name: "Resept " + this.formId,
                price: this.conversionValue,
                brand: this.$app.name,
                quantity: 1
              }]
            }
          }
        })
      }

      window.dataLayer.push({
        event: "initiateCheckout",
        products: [{
          id: "resept-" + this.formId,
          name: "Resept " + this.formId,
          price: this.conversionValue,
          brand: this.$app.name,
          quantity: 1
        }]
      })

      // GA4 implementation.
      window.dataLayer.push({ ecommerce: null })  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency: "NOK",
          value: this.conversionValue,
          items: [
            {
              item_id: "resept-" + this.formId,
              item_name: "Resept " + this.formId,
              item_brand: this.$app.name,
              price: this.conversionValue,
              quantity: 1
            }
          ]
        }
      })

      return data
    },

    slugify (string) {
      const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
      const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
      const p = new RegExp(a.split("").join("|"), "g")

      return string.toString().toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        // eslint-disable-next-line
        .replace(/[^\w\-]+/g, "") // Remove all non-word characters
        // eslint-disable-next-line
        .replace(/\-\-+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, "") // Trim - from end of text
    }
  },

  mounted () {
    EventBus.$on("init", this.handleInit)
    EventBus.$on("slideChange", this.handleSlideChange)
    EventBus.$on("checkoutComplete", this.handleCheckoutComplete)
  },

  beforeDestroy () {
    EventBus.$off("checkoutComplete", this.handleCheckoutComplete)
  },

  computed: {
    conversionValue () {
      return this.formValue.toFixed(2)
    },

    sectionStep () {
      return this.sections.findIndex(section => section === this.activeSection) + 1
    }
  },

  render () {
    return null
  }
}
</script>
