<script>
import axios from "axios"
import EventBus from "@/utils/event-bus"

export default {
  props: {
    initial: {
      type: Array,
      default () {
        return []
      }
    },

    formId: {
      type: [Number, String],
      default: null
    },

    draftOrder: {
      type: Object,
      default () {
        return {
          data: []
        }
      }
    },

    shopifyData: {
      type: Object,
      default () {
        return {
          variantId: null,
          webUrl: null,
          checkoutId: null,
          checkout: null,
          client: null,
          googleClientID: null,
          googleSessionId: null
        }
      }
    },

    shopifyCheckoutEnabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    slideFormData () {
      let filterSlides = [
        "bankIdSlide",
        "infoSlide",
        "emailSlide",
        "stopSlide",
        "photoIdSlide",
        "personalNumberSlide",
        "phoneSlide",
        "checkoutSlide",
        "checkoutConfirmationSlide"
      ]

      return this.slides
        .filter(slide => filterSlides.indexOf(slide.type) === -1)
        .map(slide => {
          let data = {
            field: slide.headline,
            value: slide.model,
            key: slide.key,
            type: slide.type,
          }

          if (slide.options && slide.options.length > 0 && slide.type === "testInputSlide") {
            data.value = slide.options.map(option => {
              return {
                field: option.title,
                value: option.model
              }
            })
          }

          if (slide.meta.required && slide.meta.model !== null) {
            data.meta = {
              field: slide.meta.title,
              value: slide.meta.model
            }
          }

          return data
        })
        .filter(slide => this.log.includes(slide.key))
        // .filter(s => s) // filter undefined
    },

    allSlideFormData () {
      return this.slides
        .map(slide => {
          let data = {
            field: slide.headline,
            value: slide.model,
            key: slide.key,
            type: slide.type,
          }

          if (slide.options && slide.options.length > 0 && slide.type === "testInputSlide") {
            data.value = slide.options.map(option => {
              return {
                field: option.title,
                value: option.model
              }
            })
          }

          if (slide.meta.required && slide.meta.model !== null) {
            data.meta = {
              field: slide.meta.title,
              value: slide.meta.model
            }
          }

          return data
        })
        .filter(slide => this.log.includes(slide.key))
    },

    slides () {
      let slides = []

      this.slideData.map(section => {
        section.slides.map(slide => {
          let updatedSlide = { ...slide, ...{ section: section.title }}

          // If draftData was found, loop through and reassign all model and meta.model fields.
          let draftData = this.draftOrder ? this.draftOrder.data : []
          let index = draftData.findIndex(draftSlide => draftSlide.key === slide.key)
          if (index >= 0) {
            let assignItem = draftData[index]
            updatedSlide.model = assignItem.value
            if (assignItem.meta && assignItem.meta.value) {
              updatedSlide.meta.model = assignItem.meta.value
            }
          }

          slides.push(updatedSlide)
        })
      })

      return slides
    },

    sections () {
      return this.slideData.map(section => {
        return section.title
      })
    },

    nextSlide () {
      if (!this.activeSlide) return null

      let conditionals = this.activeSlide.conditionals
      if (conditionals.length) {
          // https://stackoverflow.com/questions/5834318/are-variable-operators-possible
          let match = conditionals.find(condition => {
            return condition.comparator == this.activeSlide.model
          })

          if (match) {
            return this.slides.find(slide => slide.key === match.next)
          }
      }

      let next = this.slides.find(slide => slide.key === this.activeSlide.next)
      if (next) {
        return next
      }

      // Todo: Still have to check length of data.

      // Assume next section
      let sectionIndex = this.sections.indexOf(this.activeSlide.section)

      let nextSection = this.sections.length > sectionIndex ? this.sections[sectionIndex + 1] : null

      let nextSectionSlide = this.slides.find(slide => slide.section === nextSection)

      if (nextSectionSlide) {
        return nextSectionSlide
      }

      return null
    },

    prevSlide () {
      if (!this.activeSlide) return null

      const activeIndex = this.log.indexOf(this.activeSlide.key)
      if (activeIndex === 0) {
        return null
      }

      let previous = this.log[activeIndex - 1]
      let prev = this.slides.find(slide => slide.key === previous)

      return prev
    },

    coupon () {
      if (this.$app.coupon && this.$app.coupon !== null && this.$app.coupon.form_id === this.formId) {
        return this.$app.coupon.code
      }

      return null
    }
  },

  created () {
    this.activeSlide = this.slides[0]
    this.formData.coupon = this.coupon

    window.setTimeout(() => {
      this.getGoogleClientIdAndSessionId().then((obj) => {
        this.formData.googleClientID = obj.clientId
        this.formData.googleSessionID = obj.sessionId
      })
    }, 4000)

    // Restore log and formData from draftOrder.
    if (this.draftOrder && this.draftOrder.data.length > 0) {
      let lastVisitedSlideKey = this.draftOrder.data[this.draftOrder.data.length - 1].key
      let lastVisitedSlideIndex = this.slides.findIndex(slide => slide.key === lastVisitedSlideKey)
      let lastVisitedSlide = this.slides[lastVisitedSlideIndex]
      this.activeSlide = lastVisitedSlide
      let logs = this.draftOrder.data.map(slide => slide.key)
      this.log = logs

      this.formData.phone = this.draftOrder.phone
      this.formData.addons = this.draftOrder.addons  ?? []
      this.formData.automatedadvice = this.draftOrder.automatedadvice
      this.formData.coupon = this.draftOrder.coupon
      this.formData.email = this.draftOrder.email
    }
  },

  data () {
    return {
      activeSlide: null,
      log: [],
      slideData: this.initial,
      formData: {
        personalNumber: null,
        photoId: null,
        phone: null,
        phoneVerificationId: null,
        email: null,
        bankIdSessionId: null,
        formId: this.formId,
        newsletter: false,
        coupon: null,
        googleClientID: null,
        automatedadvice: null,
        addons: []
      },
      draftId: null,
      errors: null,
      errorMessage: null,
      submitting: false
    }
  },

  methods: {
    submit (autoNext = true) {
      this.submitting = true
      this.errors = null
      this.errorMessage = null
      axios.post("/api/checkout", { fields: this.slideFormData, ...this.formData })
        .then(({ data }) => {
          this.formData.orderId = data.data.order_id
          EventBus.$emit("checkoutComplete", data.data)

          if (data.data.shopify_checkout_url !== null) {
            window.location.replace(data.data.shopify_checkout_url)
          } else {
            if(autoNext){
              this.next()
            }
          }
        })
        .catch(error => {
          this.submitting = false
          this.errorMessage = error.response.data.message
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.submitting = false
        })
    },

    storeDraftOrder () {
      this.storingDraft = true

      let url = this.draftId ? `/api/drafts/${this.draftId}` : "/api/drafts"
      let method = this.draftId ? "put" : "post"

      axios({
        method,
        url,
        data: {
          formId: this.formId,
          data: this.allSlideFormData,
          automatedadvice: this.formData.automatedadvice,
          addons: this.formData.addons,
          coupon: this.formData.coupon,
          phone: this.formData.phone,
          email: this.formData.email,
        }
      })
        .then((response) => {
          this.storingDraft = false
          this.draftId = response.data.id
          return this.draftId
        })
        .catch(error => {
          this.error = error.response.data.message
          this.storingDraft = false
        })
    },

    next () {
      if (!this.nextSlide) {
        return null
      }

      this.activeSlide = this.nextSlide
    },

    prev () {
      if (!this.prevSlide) {
        return null
      }

      let activeKey = this.activeSlide.key
      this.activeSlide = this.prevSlide

      let index = this.log.findIndex(item => item === activeKey)
      this.log.splice(index, 1)
    },

    goBackToSlide (key) {
      let newActive = this.slides.find((slide => slide.key === key))
      this.activeSlide = newActive

      let index = this.log.findIndex(item => item === newActive.key)
      this.log.splice(index + 1)
    },

    setFormData (field, value) {
      this.formData[field] = value
    },

    setErrorMessage (message) {
      this.errorMessage = message
    },

    getGoogleClientIdAndSessionId () {
      return new Promise(resolve => {
        if (typeof window.ga === "undefined") {
          return resolve("")
        }

        const fallback = window.setTimeout(function() {
          //after 4 seconds, assume the script is blocked
          resolve("")
        }, 4000)

        window.ga(function() {
          // this function is called after GA library initializes
          window.clearTimeout(fallback)
          const match = document.cookie.match(/_ga_[^=]+=GS1\.1\.(\d+)\./)
          const sessionId =  match ? match[1] : null

          var trackers = window.ga.getAll()
          for (let i = 0; i < trackers.length; i++) {
            if (trackers[i].get("clientId")) {
              resolve({
                clientId: trackers[i].get("clientId"),
                sessionId
              })
            }
          }
        })
      })
    }
  },

  watch: {
    activeSlide (newValue, oldValue) {
      EventBus.$emit("slideChange", newValue, this.formData, this.slides.indexOf(newValue))

      if (this.slides.indexOf(oldValue) < this.slides.indexOf(newValue)) {
        this.log.push(newValue.key)
      }

      this.$nextTick(() => {
        this.storeDraftOrder()
      })
    }
  },

  render () {
    return this.$scopedSlots.default({
      slides: this.slides,
      sections: this.sections,
      next: this.next,
      prev: this.prev,
      nextSlide: this.nextSlide,
      prevSlide: this.prevSlide,
      activeSlide: this.activeSlide,
      formData: this.formData,
      setFormData: this.setFormData,
      slideFormData: this.slideFormData,
      submit: this.submit,
      submitting: this.submitting,
      errors: this.errors,
      errorMessage: this.errorMessage,
      setErrorMessage: this.setErrorMessage,
      goBackToSlide: this.goBackToSlide,
      draftId: this.draftId
    })
  }
}
</script>
