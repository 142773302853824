<template>
  <div class="container mx-auto sm:mt-4 md:mt-12 py-4">
    <div class="hidden md:flex justify-between items-center -ml-4 -mr-4">
      <div
        v-for="(section,index) in sections"
        :key="index"
        class="mx-4"
        :style="{ width: 100 / sections.length + '%'}">
        <span class="text-sm text-gray-800 mb-2 block whitespace-no-wrap truncate section-title">
          {{ section.title }}
        </span>

        <div class="h-2 progress-background rounded flex items-center overflow-hidden -mt-px w-full">
          <span
            :style="{ width: section.percentComplete + '%' }"
            class="h-2 inline-block progress" />
        </div>
      </div>
    </div>
    <div class="md:hidden">
      <div class="h-1 progress-background rounded flex items-center overflow-hidden -mt-px w-full">
        <span
          :style="{ width: activeSection.percentComplete + '%' }"
          class="h-2 inline-block progress" />
      </div>
      <span class="text-sm mt-2 block whitespace-no-wrap truncate section-title">
        {{ activeSection.title }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array,
      default () {
        return []
      }
    },

    initial: {
      type: Array,
      default () {
        return []
      }
    },

    active: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    sections () {
      return this.initial
        .map(section => {
          return {
            title: section.title,
            slides: section.slides.map(slide => {
              return {
                headline: slide.headline,
                key: slide.key,
                isComplete: this.slides.findIndex(gSlide => gSlide.key === slide.key) <= this.slides.indexOf(this.active)
              }
            })
          }
        })
        .map(section => {
          return {
            ...section,
            debugSlidesComplete: section.slides.filter(slide => slide.isComplete).length,
            debugTotalSlides: section.slides.length,
            percentComplete: section.slides.filter(slide => slide.isComplete).length / section.slides.length * 100
          }
        })
    },

    activeSection () {
      return this.sections.find(section => section.title === this.active.section)
    },

    numberOfSlides () {
      return this.slides.length
    },

    progressInPercent () {
      let active = this.slides.find(slide => slide.key === this.active.key)
      let index = this.slides.indexOf(active)

      return parseFloat(index / this.slides.length * 100).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
  .progress {
    transition: width 0.3s ease-out 0s;
    @apply bg-gray-800;
    background-color: var(--progress-color);
  }

  .progress-background {
    @apply bg-gray-200;
    background-color: var(--progress-background-color);
  }

  .section-title {
    color: var(--body-color);

    .theme-default & {
      @apply font-bold;
      @apply uppercase;
    }
  }
</style>
