var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-data-provider',{attrs:{"slug":_vm.$route.params.slug},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var initialFormData = ref.initialFormData;
var loading = ref.loading;
var formId = ref.formId;
var formTitle = ref.formTitle;
var formValue = ref.formValue;
var error = ref.errorMessage;
var shopifyCheckoutEnabled = ref.shopifyCheckoutEnabled;
var expressEnabled = ref.expressEnabled;
return [(error)?_c('div',{staticClass:"container mx-auto my-8"},[_c('error-message',[_vm._v(" "+_vm._s(error)+" ")])],1):_c('div',[_c('restore-draft-form-data-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var draftOrder = ref.data;
var draftOrderLoading = ref.loading;
return [(!loading && initialFormData && !draftOrderLoading)?_c('form-data-context',{attrs:{"initial":initialFormData,"draft-order":draftOrder,"form-id":formId,"shopify-checkout-enabled":shopifyCheckoutEnabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var slides = ref.slides;
var sections = ref.sections;
var nextSlide = ref.nextSlide;
var next = ref.next;
var prevSlide = ref.prevSlide;
var prev = ref.prev;
var activeSlide = ref.activeSlide;
var goBackToSlide = ref.goBackToSlide;
var formData = ref.formData;
var slideFormData = ref.slideFormData;
var submit = ref.submit;
var submitting = ref.submitting;
var errors = ref.errors;
var errorMessage = ref.errorMessage;
var setErrorMessage = ref.setErrorMessage;
var setFormData = ref.setFormData;
var draftId = ref.draftId;
return [_c('div',[_c('slide-progress',{attrs:{"initial":initialFormData,"slides":slides,"active":activeSlide}}),(_vm.$app.coupon && _vm.$app.coupon !== null && _vm.$app.coupon.form_id === formId)?_c('coupon-banner'):_vm._e(),_c('slide',{attrs:{"form-data":formData,"set-form-data":setFormData,"slide-form-data":slideFormData,"form-id":formId,"form-title":formTitle,"form-slug":_vm.$route.params.slug,"active":activeSlide,"prev-slide":prevSlide,"next-slide":nextSlide,"prev":prev,"next":next,"submit":submit,"submitting":submitting,"initial-form-data":initialFormData,"go-back-to-slide":goBackToSlide,"set-error-message":setErrorMessage,"form-value":formValue,"slides":slides,"express-enabled":expressEnabled,"draft-id":draftId}}),(errors || errorMessage)?_c('validation-errors',{staticClass:"container mx-auto max-w-3xl px-4 -mt-24",attrs:{"errors":errors,"error-message":errorMessage}}):_vm._e(),_c('tracking-context',{attrs:{"sections":sections,"form-title":formTitle,"form-id":formId,"form-value":formValue}})],1)]}}],null,true)}):_vm._e()]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }