<template>
  <div>
    <form-data-provider :slug="$route.params.slug">
      <template slot-scope="{ initialFormData, loading, formId, formTitle, formValue, errorMessage: error, shopifyCheckoutEnabled, expressEnabled }">
        <div
          v-if="error"
          class="container mx-auto my-8">
          <error-message>
            {{ error }}
          </error-message>
        </div>
        <div v-else>
          <restore-draft-form-data-provider>
            <template slot-scope="{ data: draftOrder, loading: draftOrderLoading }">
              <form-data-context
                :initial="initialFormData"
                :draft-order="draftOrder"
                :form-id="formId"
                :shopify-checkout-enabled="shopifyCheckoutEnabled"
                v-if="!loading && initialFormData && !draftOrderLoading">
                <template slot-scope="{ slides, sections, nextSlide, next, prevSlide, prev, activeSlide, goBackToSlide, formData, slideFormData, submit, submitting, errors, errorMessage, setErrorMessage, setFormData, draftId }">
                  <div>
                    <slide-progress
                      :initial="initialFormData"
                      :slides="slides"
                      :active="activeSlide" />
                    <coupon-banner v-if="$app.coupon && $app.coupon !== null && $app.coupon.form_id === formId" />
                    <slide
                      :form-data="formData"
                      :set-form-data="setFormData"
                      :slide-form-data="slideFormData"
                      :form-id="formId"
                      :form-title="formTitle"
                      :form-slug="$route.params.slug"
                      :active="activeSlide"
                      :prev-slide="prevSlide"
                      :next-slide="nextSlide"
                      :prev="prev"
                      :next="next"
                      :submit="submit"
                      :submitting="submitting"
                      :initial-form-data="initialFormData"
                      :go-back-to-slide="goBackToSlide"
                      :set-error-message="setErrorMessage"
                      :form-value="formValue"
                      :slides="slides"
                      :express-enabled="expressEnabled"
                      :draft-id="draftId"
                    />
                    <validation-errors
                      v-if="errors || errorMessage"
                      :errors="errors"
                      :error-message="errorMessage"
                      class="container mx-auto max-w-3xl px-4 -mt-24" />
                    <tracking-context
                      :sections="sections"
                      :form-title="formTitle"
                      :form-id="formId"
                      :form-value="formValue" />
                  </div>
                </template>
              </form-data-context>
            </template>
          </restore-draft-form-data-provider>
        </div>
      </template>
    </form-data-provider>
  </div>
</template>

<script>
import CouponBanner from "../components/CouponBanner"
import Slide from "../components/Slide"
import SlideProgress from "../components/SlideProgress"
import FormDataProvider from "../components/FormDataProvider"
import FormDataContext from "../components/FormDataContext"
import RestoreDraftFormDataProvider from "../components/RestoreDraftFormDataProvider"
import TrackingContext from "../components/TrackingContext"

export default {
  components: {
    CouponBanner,
    Slide,
    SlideProgress,
    FormDataContext,
    FormDataProvider,
    RestoreDraftFormDataProvider,
    TrackingContext
  }
}
</script>

