<script>
import axios from "axios"

export default {
  props: {
    slug: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      loading: true,
      data: null,
      formId: null,
      formTitle: null,
      formValue: null,
      shopifyCheckoutEnabled: false,
      expressEnabled: false,
      errorMessage: null
    }
  },

  mounted () {
    this.fetchForm()
  },

  methods: {
    fetchForm () {
      this.loading = false
      return axios.get(`/api/forms/${this.slug}`)
        .then(({ data }) => {
          this.loading = false
          this.data = data.data
          this.formId = data.id
          this.formTitle = data.title
          this.formValue = data.conversion_value
          this.shopifyCheckoutEnabled = data.shopify_checkout_enabled
          this.expressEnabled = data.express_enabled
        })
        .catch(e => {
          this.loading = false
          this.errorMessage = e.response.data.message

          throw e
        })
    }
  },

  render () {
    return this.$scopedSlots.default({
      initialFormData: this.data,
      loading: this.loading,
      formId: this.formId,
      formTitle: this.formTitle,
      formValue: this.formValue,
      shopifyCheckoutEnabled: this.shopifyCheckoutEnabled,
      expressEnabled: this.expressEnabled,
      errorMessage: this.errorMessage
    })
  }
}
</script>
