<template>
  <div>
    <component
      :is="active.type"
      :data="active"
      :form-data="formData"
      :set-form-data="setFormData"
      :slide-form-data="slideFormData"
      :form-id="formId"
      :form-title="formTitle"
      :form-slug="formSlug"
      :form-value="formValue"
      :key="active.key"
      :submit="submit"
      :submitting="submitting"
      :prev="prev"
      :next="next"
      :go-back-to-slide="goBackToSlide"
      @continue="next"
      :slides="slides"
      :initial-form-data="initialFormData"
      :set-error-message="setErrorMessage"
      :express-enabled="expressEnabled"
      :draft-id="draftId"
      v-model="active.model">
      <template v-slot:header>
        <div class="px-4 container mx-auto pt-6 md:pt-24 md:max-w-3xl">
          <headline class="font-bold text-center">
            {{ active.headline }}
          </headline>
          <div
            class="sm:text-lg mt-4 text-center text-muted"
            v-if="active.description">
            <editor-content v-html="htmlDescription" />
          </div>
        </div>
      </template>

      <slide-navigation
        slot-scope="{ isValid, isHidden }"
        :is-valid="isValid"
        :next-slide="nextSlide"
        :next="next"
        :prev-slide="prevSlide"
        :prev="prev"
        :is-hidden="isHidden"
      />
    </component>
  </div>
</template>

<script>
import marked from "marked"

export default {
  props: {
    active: {
      type: Object,
      default () {
        return {}
      }
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    },

    setFormData: {
      type: Function,
      default: () => {}
    },

    slideFormData: {
      type: Array,
      default () {
        return []
      }
    },

    formId: {
      type: Number,
      default: null
    },

    formTitle: {
      type: String,
      default: null
    },

    formSlug: {
      type: String,
      default: null
    },

    formValue: {
      type: Number,
      default: 0
    },

    prevSlide: {
      type: [Object, null],
      default () {
        return null
      }
    },

    nextSlide: {
      type: [Object, null],
      default () {
        return null
      }
    },

    next: {
      type: Function,
      default: () => {}
    },

    prev: {
      type: Function,
      default: () => {}
    },

    goBackToSlide: {
      type: Function,
      default: () => {}
    },

    setErrorMessage: {
      type: Function,
      default: () => {}
    },

    submit: {
      type: Function,
      default: () => {}
    },

    submitting: {
      type: Boolean,
      default: false
    },

    initialFormData: {
      type: Array,
      default () {
        return []
      }
    },

    slides: {
      type: Array,
      default () {
        return []
      }
    },

    expressEnabled: {
      type: Boolean,
      default: false
    },

    draftId : {
      type: String,
      default: null
    }
  },

  components: {
    infoSlide: () => import("../components/slides/InfoSlide.vue"),
    stopSlide: () => import("../components/slides/StopSlide.vue"),
    confirmSlide: () => import("../components/slides/ConfirmSlide.vue"),
    selectSlide: () => import("../components/slides/SelectSlide.vue"),
    radioListSlide: () => import("../components/slides/RadioListSlide.vue"),
    checkListSlide: () => import("../components/slides/CheckListSlide.vue"),
    booleanSlide: () => import("../components/slides/BooleanSlide.vue"),
    inputSlide: () => import("../components/slides/InputSlide.vue"),
    emailSlide: () => import("../components/slides/EmailSlide.vue"),
    newsletterSlide: () => import("../components/slides/NewsletterSlide.vue"),
    privacySlide: () => import("../components/slides/PrivacySlide.vue"),
    phoneSlide: () => import("../components/slides/PhoneSlide.vue"),
    photoIdSlide: () => import("../components/slides/PhotoIdSlide.vue"),
    photoSlide: () => import("../components/slides/PhotoSlide.vue"),
    bankIdSlide: () => import("../components/slides/BankIdSlide.vue"),
    personalNumberSlide: () => import("../components/slides/PersonalNumberSlide.vue"),
    checkoutSlide: () => import("../components/slides/CheckoutSlide.vue"),
    ctaSlide: () => import("../components/slides/CtaSlide.vue"),
    checkoutConfirmationSlide: () => import("../components/slides/CheckoutConfirmationSlide.vue"),
    checkoutConfirmationWithReviewSlide: () => import("../components/slides/CheckoutConfirmationWithReviewSlide.vue"),
    reviewSlide: () => import("../components/slides/ReviewSlide.vue"),
    slideNavigation: () => import("../components/SlideNavigation.vue"),
    orderSummarySlide: () => import("../components/slides/OrderSummarySlide.vue"),
    bmiSlide: () => import("../components/slides/BMISlide.vue"),
    vippsExpressCheckoutSlide: () => import("../components/slides/VippsExpressCheckoutSlide.vue"),
    aav2: () => import("../components/slides/AutomatedAdviseSlideV2.vue"),
    refWriteSlide: () => import("../components/slides/ReferenceCodeWriteSlide.vue"),
    refReadSlide: () => import("../components/slides/ReferenceCodeReadSlide.vue"),
    aaRefSlide: () => import("../components/slides/AutomatedAdviseRefSlide.vue"),
    aaAggregateSlide: () => import("../components/slides/AutomatedAdviceAggregateSlide.vue"),
    testInputSlide: () => import("../components/slides/TestInputSlide.vue")
  },

  computed: {
    htmlDescription () {
      if (!this.active.description) return null
      return marked.parse(this.active.description, { sanitize: true })
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-muted {
    color: var(--text-muted-color);
  }
</style>
