<script>
import axios from "axios"

export default {
  data () {
    return {
      loading: false,
      data: null
    }
  },

  mounted () {
    if (this.$route.query.draft_order_id) {
      this.fetchData()
    }
  },

  methods: {
    fetchData () {
      this.loading = true
      axios.get(`/api/drafts/${this.$route.query.draft_order_id}`)
        .then(response => {
          this.data = response.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  },

  render () {
    return this.$scopedSlots.default({
      data: this.data,
      loading: this.loading
    })
  }
}
</script>
